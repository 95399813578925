import React from 'react';
import {
    Card,
    Stack,
    Typography,
  } from '@mui/material';

export default function UserHead({ user }) {
    return (
        <Card>
          <Stack spacing={3} sx={{ p: 3 }}>
            <Typography variant="h3" sx={{
              textAlign: 'center',
            }} gutterBottom>
              {user.name}
            </Typography>
          </Stack>
        </Card>
    )
}