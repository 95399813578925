import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import {
  Stack,
  Button,
  Typography,
  Card,
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
// mock
import { useQuery } from 'react-query';
import { api } from '../api';
import PageLoader from '../components/loader'
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { type User } from '../types/user';
import { LoadingButton } from '@mui/lab';
import { useMutation, QueryClient } from 'react-query';
import {toast} from 'react-toastify'

// ----------------------------------------------------------------------

export default function UserPage() {
  const { uuid }: any = useParams();

  const queryClient = new QueryClient()

  const navigate = useNavigate();

  const { data, isLoading, error }: { data: User, isLoading: boolean, error: any } = useQuery(['user'], () => api.getUser(uuid), {
    cacheTime: 0,
    staleTime: 0,
  })

  const INITIAL_DATA = { ...data }

  const [user, setUser] = useState<User>(INITIAL_DATA as User);

  useEffect(() => {
    if (data) {
      setUser(data)
    }
  }, [data])


  const { mutate, isLoading: isLoadingUpdate } = useMutation(['user-update'], () => api.updateUser(uuid, user), {
    onSuccess: () => {
      setTimeout(() => {
        navigate(`/dashboard/user/profile/${uuid}`)
      }, 1500)
      
      queryClient.invalidateQueries('user')
      toast.success('Utilisateur modifié avec succès')
    },
    onError: () => {
      toast.error('Une erreur est survenue lors de la modification de l\'utilisateur')
    },
    
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  if (error) {
    return <>
      <div>
        <h1>Erreur</h1>
      </div>
    </>
  }

  return (
    <>
      <Helmet>
        <title>Profil de l'utilisateur:</title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Profil client
        </Typography>
        <Button variant="contained" onClick={() => navigate(`/dashboard/user/profile/${uuid}`)} color='error' startIcon={<Iconify icon="eva:close-outline" />}>
          Annuler
        </Button>
      </Stack>

      {isLoading ? <PageLoader /> : <>
        <Card sx={{
          padding: 2
        }}>
          <Stack>
            <Typography variant="h4" gutterBottom>
              Modifier l'utilisateur {user.name}
            </Typography>
          </Stack>
          <hr style={{
            margin: '2rem 0'
          }} />
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='name' label={'Nom et prénom'} placeholder='Nom et prénom' onChange={handleChange} value={user.name} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <Typography variant='subtitle2'>
              Le numéro de téléphone doit être au format international +33 ou doit commencé par un 0
            </Typography>
            <TextField name='phoneNumber' label={'Numéro de téléphone'} placeholder='Numéro de téléphone' onChange={handleChange} value={user.phoneNumber} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='email' label={'Email'} placeholder='Email' onChange={handleChange} value={user.email} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='address' label={'Numéro et rue'} placeholder='Numéro et rue' onChange={handleChange} value={user.address} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='city' label={'Ville'} placeholder='Ville' onChange={handleChange} value={user.city} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='zipCode' label={'Code postal'} placeholder='Code postal' onChange={handleChange} value={user.zipCode} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='personne_foyer' label={'Personne inscrit au foyer fiscal'} placeholder='Personne inscrit au foyer fiscal' onChange={handleChange} value={user.personne_foyer} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <TextField name='revenue_fiscal_reference' label={'Revenu foyer fiscal (RFR)'} placeholder='Revenue foyer fiscal (RFR)' onChange={handleChange} value={user.revenue_fiscal_reference} />
          </Stack>
          <Stack spacing={2} sx={{
            marginBottom: 2
          }}>
            <Box sx={{ width: '100%' }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Profile de prime</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={user.prime_renov_status}
                  label="Profile de prime"
                  name="prime_renov_status"
                  onChange={handleChange}
                >
                  <MenuItem selected={user.prime_renov_status === 'bleu'} value={"bleu"}>MaPrimeRénov'Bleu</MenuItem>
                  <MenuItem value={"jaune"}>MaPrimeRénov'Jaune</MenuItem>
                  <MenuItem value={"violet"}>MaPrimeRénov'Violet</MenuItem>
                  <MenuItem value={"rose"}>MaPrimeRénov'Rose</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>
          <Stack spacing={2} sx={{}}>
            <LoadingButton
              variant="contained"
              loading={isLoadingUpdate}
              disabled={isLoadingUpdate}
              onClick={() => mutate()}
            >
              Enregistrer
            </LoadingButton>
          </Stack>
        </Card>
      </>}
      <Card sx={{
        padding: 2,
        marginTop: 2
      }}>
        <Typography>
          Dernière modification le {new Date(user.updatedAt).toLocaleDateString('fr-FR', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })} à {new Date(user.updatedAt).toLocaleTimeString('fr-FR')}
        </Typography>
      </Card>
    </>
  );
}