import Typography from '@mui/material/Typography';
import {type User} from '../../types/user';

export default function Coordonnee({ user }: { user: User }) {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                Email: {user.email}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Numéro de téléphone: {user.phoneNumber}
            </Typography>
        </>
    )
}