import { useState } from 'react';
// @mui
import { Link, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { useRecoilState } from "recoil";
import { user } from "../../../hooks/atom/user";
import axios from 'axios';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const [token, setToken] = useState('');

  // eslint-disable-next-line
  const [admin, setAdmin] = useRecoilState(user);

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    await axios.get(`/client/login/${token}`)
      .then(({ data }) => {
        setAdmin(data)

        localStorage.setItem('token', data.token)

        toast.success('Connexion réussie')
        
        setTimeout(() => {
          window.location.reload()
        }, 1500)

        return data
      })
      .catch((err) => {
        toast.error('Vos identifiant sont incorrect')
        return err
      })
    setIsLoading(false);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField name="token" label="Token utilisateur" placeholder='Merci de rentrer votre token qui vous à été envoyer par mail' onChange={e => setToken(e.target.value)} />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Token perdu ?
        </Link>
      </Stack>

      <LoadingButton fullWidth loading={isLoading} size="large" type="submit" variant="contained" onClick={e => handleClick(e)}>
        Se connecter
      </LoadingButton>
    </>
  );
}
