import { Helmet } from 'react-helmet-async';
import { User } from '../types/user';
// @mui
import {
  Stack,
  Button,
  Typography,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
// mock
import { useQuery } from 'react-query';
import { api } from '../api';
import PageLoader from '../components/loader'
import { useParams } from 'react-router-dom';

import UserHead from '../components/User/UserHead';
import UserBody from '../components/User/UserBody';

import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

export default function UserPage() {

  const { uuid }: any = useParams();

  const { data, isLoading, error }: {data: User, isLoading: boolean, error: any} = useQuery(['user'], () => api.getUser(uuid), {
    cacheTime: 0,
    staleTime: 0,
  })

  const navigate = useNavigate();

  if (error) {
    return <>
      <div>
        <h1>Erreur</h1>
      </div>
    </>
  }

  return (
    <>
      <Helmet>
        <title>Profil de l'utilisateur:</title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Profil client
        </Typography>
        <Button variant="contained" onClick={() => navigate(`/dashboard/user/update/${uuid}`)} startIcon={<Iconify icon="eva:plus-fill" />}>
          Modifier
        </Button>
      </Stack>

      {isLoading ? <PageLoader /> : <>
        <UserHead user={data} />
        <UserBody user={data} />
      </>}
    </>
  );
}
