import { Typography } from "@mui/material";

export default function Adresse({ user }) {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                Adresse: {user.address}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Ville: {user.city}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Code Postal: {user.zipCode}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Pays: France
            </Typography>
        </>
    )
}