import { Navigate, Routes, Route } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import {useRecoilValue} from 'recoil'
import {userState} from './hooks/atom/user';
import UserPage from './pages/UserPage';
import UserUpdate from './pages/UserUpdate';
import UserProfile from './pages/UserProfile';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';


// ----------------------------------------------------------------------

function Layout (Layout: any, Component: any) {
    return (
      <Layout>
        <Component />
      </Layout>
    );
}

export default function Router() {
  const user = useRecoilValue(userState);
  if(!user.isConnected && !localStorage.getItem('token')) {
    return <Routes>
      <Route path="/" index element={<LoginPage/>} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  }

  return <Routes>
    <Route path="/" element={<Navigate to="/dashboard/app" />} />
    <Route path="/dashboard/app" index element={Layout(DashboardLayout, DashboardAppPage)} />
    <Route path="/dashboard/user" element={Layout(DashboardLayout, UserPage)} />
    <Route path="/dashboard/user/profile/:uuid" element={Layout(DashboardLayout, UserProfile)} />
    <Route path="/dashboard/user/update/:uuid" element={Layout(DashboardLayout, UserUpdate)} />
    <Route path="/404" element={<Page404/>} />
    <Route path="*" element={<Navigate to="/404" />} />
  </Routes>;
}
