import Typography from '@mui/material/Typography';

export default function Info({ user }) {
    return (
        <>
            <Typography variant="subtitle1" gutterBottom>
                L'utilisateur à remplis son RFR ? {user.hasDefault ? 'Non' : 'Oui'}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Revenue fiscal de référence (RFR): {user.revenue_fiscal_reference}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Profile de prime: {user.prime_renov_status}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                L'utilisateur souhaite: {user.subject}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Commentaire utilisateur: {user.text}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Créé le: {user.createdAt}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                Modifié le: {user.updatedAt}
            </Typography>
        </>
    )
}